<template>
  <div>
    <v-container>
      <v-row cols="12">
        <v-col>
          <img
            @click="$router.push('/')"
            width="120px"
            src="@/assets/logo.png"
            alt=""
            class=""
          />
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="ma-0 my-auto " cols="6">
          <v-btn
            class="float-right ma-0 mr-auto"
            dark
            @click="applyNow"
            color="#FF5F1F"
          >
            Apply Now
            <v-icon class="ml-2">fal fa-long-arrow-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <h1
      class="container text-center"
      style="letter-spacing: 3px;font-size:40px ;color: darkblue"
    >
      2 Wheeler Loan EMI Calculator
    </h1>
    <v-container>
      <LoanCalculator />
    </v-container>
    <v-container style="min-height: 70vh">
      <v-row>
        <v-col>
          <h2
            class="mt-3 text-center"
            style="font-size: 40px; letter-spacing: 3px;"
          >
            Move Smart with Ontrack Two-Wheeler Loans
          </h2>
          <p class="mt-3">
            Two-Wheeler Loans or Bike Loans are a smart way of owning a
            Motorcycle in today’s time. Owning a bike has two frequent costs
            associated with it, one of the fuel and other of maintenance, and
            both these over the bike’s lifetime can cost as much as the bike at
            the time of purchase. To address this problem, Ontrack is offering
            2-Wheeler loans for as long as 42 months. You’ll only pay a small
            portion of the vehicle’s cost over a time that you find comfortable.
          </p>
          <v-row dense class="mt-3">
            <v-col class="mt-3" md="6" sm="12" cols="12">
              <v-card class="fill-height">
                <v-card-text>
                  <v-icon class="my-3" size="50" color="primary"
                    >fab fa-wpforms
                  </v-icon>
                  <h2>
                    Super Simple Process
                  </h2>
                  <p>
                    Ontrack has partnered with Ontrack to assist you with your
                    bike loan. All you have to do now is fill in the simple form
                    by clicking on “Apply Now”. Someone from our team or Ontrack
                    will reachout to you shortly and assist with the
                    application.
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col class="mt-3" md="6" sm="12" cols="12">
              <v-card class="fill-height">
                <v-card-text>
                  <v-icon class="my-3" size="50" color="primary"
                    >fab fa-creative-commons-zero
                  </v-icon>
                  <h2>
                    Zero Hidden Charges
                  </h2>
                  <p>
                    We stand by this, our team will communicate all the charges
                    before you make a decision. No last minute surprise. We
                    really want your bike purchase experience with us to be
                    smooth.
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col class="mt-3" md="6" sm="12" cols="12">
              <v-card class="fill-height">
                <v-card-text>
                  <v-icon class="my-3" size="50" color="primary"
                    >fas fa-receipt
                  </v-icon>
                  <h2>
                    Minimum Downpayment
                  </h2>
                  <p>
                    Seriously, we really don’t want to share too much with us.
                    Infact you can get a bike loan from us without a bank
                    account or even income proof. If you have never borrowed
                    before, we’ll be happy to be your first lender.
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col class="mt-3" md="6" sm="12" cols="12">
              <v-card class="fill-height">
                <v-card-text>
                  <v-icon class="my-3" size="50" color="primary"
                    >fas fa-chart-pie
                  </v-icon>
                  <h2>
                    Lowest Processing Fees
                  </h2>
                  <p>
                    Now that’s one of the biggest advantage when you apply via
                    Ontrack. Usually you pay about 3-4% of your loan amount as
                    processing fees via any dealership. With us, you’ll only pay
                    1%.
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense class="mt-3">
            <v-col class="mt-3" md="6" sm="12" cols="12">
              <v-card class="fill-height">
                <v-card-text>
                  <v-icon class="my-3" size="50" color="primary">
                    fa-check
                  </v-icon>
                  <h2>
                    Lowest interest of 11.9% only
                  </h2>
                  <p>
                    Ontrack has partnered with Ontrack to assist you with your
                    bike loan. All you have to do now is fill in the simple form
                    by clicking on “Apply Now”. Someone from our team or Ontrack
                    will reachout to you shortly and assist with the
                    application.
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col class="mt-3" md="6" sm="12" cols="12">
              <v-card class="fill-height">
                <v-card-text>
                  <v-icon class="my-3" size="50" color="primary"
                    >fa-calendar
                  </v-icon>
                  <h2>
                    42 Months repayment tenure
                  </h2>
                  <p>
                    We stand by this, our team will communicate all the charges
                    before you make a decision. No last minute surprise. We
                    really want your bike purchase experience with us to be
                    smooth.
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col class="mt-3" md="6" sm="12" cols="12">
              <v-card class="fill-height">
                <v-card-text>
                  <v-icon class="my-3" size="50" color="primary"
                    >fa-thumbs-up
                  </v-icon>
                  <h2>
                    Lowest processing fee
                  </h2>
                  <p>
                    Seriously, we really don’t want to share too much with us.
                    Infact you can get a bike loan from us without a bank
                    account or even income proof. If you have never borrowed
                    before, we’ll be happy to be your first lender.
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col class="mt-3" md="6" sm="12" cols="12">
              <v-card class="fill-height">
                <v-card-text>
                  <v-icon class="my-3" size="50" color="primary"
                    >fa-smile
                  </v-icon>
                  <h2>
                    Upto 95% funding
                  </h2>
                  <p>
                    Now that’s one of the biggest advantage when you apply via
                    Ontrack. Usually you pay about 3-4% of your loan amount as
                    processing fees via any dealership. With us, you’ll only pay
                    1%.
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-container style="min-height: 90vh">
      <v-row class="my-auto">
        <v-col>
          <h2
            class="mt-3 text-center"
            style="font-size: 40px; letter-spacing: 2px;"
          >
            Benefits of Using Bike Loan EMI Calculator
          </h2>
          <h3 class="font-weight-black py-2">
            <v-icon size="20" color="primary">fa-check </v-icon>
            Quick and Hassle-Free
          </h3>
          <p class="pb-2">
            Manual calculations tend to be lengthy, and can consequently take a
            long time to complete. If you try to complete the same too quickly,
            it can lead to erroneous assumptions. A bike EMI calculator, on the
            other hand, requires milliseconds to assess the data you provide and
            compute your EMI liabilities from the said loan.
          </p>
          <h3 class="font-weight-black py-2">
            <v-icon size="20" color="primary">fa-check </v-icon>
            Easy to Use
          </h3>
          <p class="pb-2">
            The calculator’s interface on Ontrack’s website is simple to
            understand and navigate; to the point that almost anyone can operate
            it. The fields are marked appropriately, and you must only enter the
            figures accordingly to reach the desired outcome.
          </p>
          <h3 class="font-weight-black py-2">
            <v-icon size="20" color="primary">fa-check </v-icon>
            Always Accurate
          </h3>
          <p class="pb-2">
            When you calculate your bike loan EMIs manually, you will always be
            unsure about whether the results you reach are accurate or not.
            Remember that even a minor mistake in calculations can seriously
            undermine your assessment of the said loan. To eliminate such risks,
            it is always better to use a bike loan calculator. No matter how
            many times you use the tool, it will never display erroneous
            results.
          </p>
          <h3 class="font-weight-black py-2">
            <v-icon size="20" color="primary">fa-check </v-icon>
            Free and Unrestricted Usage
          </h3>
          <p class="pb-2">
            The bike loan EMI calculator available on our website is completely
            free to use. Moreover, you can utilise the tool as many times as you
            wish or need. We do not restrict usage in any way. This can be an
            extremely useful feature, especially when you are comparing the
            various bike loans on offer to check for the most affordable one.
          </p>
          <h3 class="font-weight-black py-2">
            <v-icon size="20" color="primary">fa-check </v-icon>
            Convenient
          </h3>
          <p class="pb-2">
            Lastly, using such an online loan calculator means that you do not
            need to bother undertaking all the hassle with a pen, paper and
            calculations. Manual calculations may force you to engage in
            complicated multiplications and divisions, which require intense
            rough work. Fortunately, in case of bike EMI calculator, the tool
            does all the hard work on your behalf, allowing you to determine
            your monthly liabilities on the go.
          </p>
          <h3 class="font-weight-black py-2">
            <v-icon size="20" color="primary">fa-check </v-icon>
            Additional Details
          </h3>
          <p class="pb-2">
            Besides the monthly instalment amount, these calculators often also
            display other useful information to borrowers. For example, some
            bike loan calculators also display a loan’s amortisation table as
            well. With it, you can see just how the interest and principal
            component of your EMIs change as you move on with repayment. A few
            tools may also highlight your total interest amount.
          </p>
        </v-col>
      </v-row>
      <v-row class="my-4 py-2" style="background-color: lightyellow">
        <v-col md="8" sm="6" xs="6" lg="8" xl="8" class="text-left">
          <h4 class="my-auto">
            Your first bike is just a few clicks away
          </h4>
        </v-col>
        <v-col class="my-auto text-right ">
          <v-btn color="#FF5F1F" dark @click="applyNow"> Apply Now </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container style="min-height: 50vh">
      <h3
        class="mt-3 text-center"
        style="font-size: 40px; letter-spacing: 2px;"
      >
        Super Simple 3-step process
      </h3>
      <v-row class="my-auto py-4">
        <v-col cols="12" md="12" sm="12">
          <h3>
            <span class="font-weight-black">Step 1.</span> Fill Your Basic
            Details
          </h3>
          <p>
            Fill in your basic details and someone from Ontrack will reach out
            to you immediately.
          </p>
        </v-col>
        <v-col cols="12" md="12" sm="12">
          <h3><span class="font-weight-black">Step 2.</span> Documentation</h3>
          <p>
            We’ll help you with documentation and understand your eligibility.
            once eligible, we’ll underwrite your loan and come back with an
            offer.
          </p>
        </v-col>
        <v-col cols="12" md="12" sm="12">
          <h3><span class="font-weight-black">Step 3.</span> Purchase Bike</h3>
          <p>
            If our loan terms are acceptable by you, our team will assist you
            with the purchase process. You can ride home a brand new Motorcycle.
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-container style="min-height: 50vh">
      <v-row>
        <v-col>
          <h2
            class="mt-3 text-center"
            style="font-size: 40px; letter-spacing: 2px;"
          >
            Bike Loan Tax Benefits
          </h2>
          <p>
            If you are currently paying off your bike loan dues, you should know
            that you may be eligible for tax exemptions on the same.However, you
            can only claim this benefit if the two-wheeler in question is
            operated for business purposes only.
          </p>
          <p>
            This also means that salaried professionals cannot claim any tax
            deductions of their bike loans. If you are a self-employed
            individual availing a loan to buy a bike for your business, you can
            enjoy three types of tax benefits. These are as follows:
          </p>
          <p>
            <v-icon size="20" color="primary">fa-check </v-icon>
            Save Interest as a Business Expense - You can claim a tax deduction
            on your bike loan’s yearly interest payments by including this
            amount under your business expenses.
          </p>
          <p>
            <v-icon size="20" color="primary">fa-check </v-icon>
            Depreciation Cost - a portion of the sum you spend to maintain your
            bike in perfect condition can also be claimed as tax deductions
          </p>
          <p>
            <v-icon size="20" color="primary">fa-check </v-icon>
            Transportation Cost - All your fuel expenses on the two-wheeler can
            be claimed as tax-free expenses in any given year.
          </p>
          <p>
            Keep in mind that if the government finds any discrepancies
            regarding the usage of your claimed business two-wheeler, your tax
            deductions from the same may be revoked. With the above information
            on bike loan EMI calculation and such, you can easily proceed to
            avail a loan to purchase your dream two-wheeler without
            reservations!
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-container style="min-height: 50vh">
      <v-row>
        <v-col>
          <h2
            class="mt-3 text-center"
            style="font-size: 40px; letter-spacing: 2px;"
          >
            Loan Eligibility
          </h2>
          <p>
            For the approval of your bike loan from Ontrack, you need to fulfil
            the stipulated eligibility conditions. Here’s a look at the
            eligibility conditions:
          </p>
          <p>
            <v-icon size="20" color="primary">fa-check </v-icon>
            Nationality: For making a loan application, you need to be an Indian
            citizen.
          </p>
          <p>
            <v-icon size="20" color="primary">fa-check </v-icon>
            Age: The minimum age for receiving an Ontrack Bike Loan is 18 years,
            a person can apply for a bike loan till the age of 65 years.
          </p>
          <p>
            <v-icon size="20" color="primary">fa-check </v-icon>
            Occupation: Salaried, Self-Employed, Agro-Employment and Dependants
          </p>
          <p>
            <v-icon size="20" color="primary">fa-check </v-icon>
            Bank Account: Not Mandatory
          </p>
          <p>
            <v-icon size="20" color="primary">fa-check </v-icon>
            Income Proof: With or without income proof, even new to credit can
            be processed
          </p>
        </v-col>
      </v-row>
      <v-row class="py-2 my-4" style="background-color: lightyellow">
        <v-col md="8" sm="6" xs="6" lg="8" xl="8" class="text-left">
          <h4 class="my-auto">
            Take the first step towards your dream bike.
          </h4>
        </v-col>
        <v-col class="my-auto text-right ">
          <v-btn color="#FF5F1F" dark @click="applyNow"> Apply Now </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <div>
      <v-col class="ma-0 pa-0 py-15 text-center" style="background: #000">
        <v-col md="10" class="mx-auto pa-0 py-15">
          <v-row justify="center" class="mx-auto" no-gutters>
            <v-col class="mx-auto">
              <v-btn
                v-for="(link, index) in links"
                :key="index"
                color="white"
                text
                rounded
                class="my-2 mx-auto"
                :to="link.link"
              >
                {{ link.name }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mt-4">
            <v-col class="address lighten-1 text-center white--text" cols="12">
              <p class="footer-text color-p">
                Copyright © 2022 Ontrack Technologies Pvt. Ltd. <br />
                All rights reserved.
              </p>
            </v-col>
            <v-col class="made-with">
              <p class="made-with-text">
                Made with
                <v-icon color="red">
                  fal fa-heart
                </v-icon>
                Ontrack Labs
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
    </div>
  </div>
</template>
<script>
import LoanCalculator from "@/components/LoanCalculator.vue";

export default {
  name: "BikeLoanCalculator",
  components: {
    LoanCalculator,
  },
  data() {
    return {
      links: [
        {
          name: "Terms and Conditions",
          link: "/finance/TermsConditions",
        },
        {
          name: "Privacy Policy",
          link: "/PrivacyPolicy",
        },
        {
          name: "Bike Loan Calculator",
          link: "/bike-loan-calculator",
        },
        {
          name: "Sitemap",
          link: "/Sitemap",
        },
      ],
    };
  },
  methods: {
    applyNow() {
      this.$router.push("/finance");
    },
  },
};
</script>
<style scoped>
h2 {
  font-size: 27px;
  color: darkblue;
  margin-bottom: 20px;
}

p {
  font-size: 15px;
  color: darkblue;
}

h3 {
  color: darkblue;
}

.footer-text {
  margin: 0;
  color: white;
  padding: 0 10px;
}

.made-with {
  margin-top: -30px;
}

.made-with-text {
  color: white;
  font-size: 12px;
}

.address {
  margin: 0;
}
</style>
